import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Logo } from '@components/atoms/Logo/Logo';

export interface FallbackCardProps extends PropsWithClassProps {
  logoWidth?: number;
  logoHeight?: number;
}

export const FallbackCard = ({ classProps, logoWidth, logoHeight }: FallbackCardProps) => {
  const cardStyle = twMerge(
    'bg-gray-100 dark:bg-gray-820 grid items-center justify-center w-full h-full',
    classProps?.root,
  );
  return (
    <div className={cardStyle}>
      <Logo width={logoWidth} height={logoHeight} />
    </div>
  );
};
