import React, { PropsWithChildren } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Spinner } from '@components/atoms/Spinner/Spinner';
import { IconPlayTvguide } from '@assets/icon-play-tvguide';

export interface ButtonProps extends PropsWithChildren, PropsWithClassProps<'button'> {
  hasAction?: boolean;
  title?: string;
  id?: string;
  isLoading?: boolean;
  onAction?: () => void;
  link?: string;
  openInTab?: boolean;
  buttonStyle?: 'primary' | 'secondary' | 'tertiary' | 'viewLink';
  full?: boolean;
}

export const Button = ({
  title,
  id,
  hasAction,
  isLoading,
  link,
  onAction,
  classProps,
  openInTab,
  buttonStyle = 'secondary',
  full,
  children,
}: ButtonProps) => {
  const buttonStyles = {
    primary: 'border border-primary bg-transparent text-white hover:bg-primary',
    secondary: 'bg-gray-700 text-white backdrop-blur-sm hover:bg-white hover:text-gray-820',
    tertiary: 'bg-white/70 text-gray-820 hover:bg-white',
    viewLink: 'font-herokid font-medium uppercase tracking-2px text-gray-400 shadow-none hover:text-white',
  };

  const mainStyle = twMerge(
    'inline-flex min-h-[43px] cursor-pointer place-content-center rounded-full px-8 py-2.5 font-herokid text-2xs font-bold uppercase leading-body tracking-widest shadow-card-sm transition-colors duration-300 items-center gap-2',
    buttonStyles[buttonStyle],
    full && 'w-full flex',
    classProps?.button,
  );
  return hasAction ? (
    <button className={mainStyle} onClick={onAction} id={id}>
      {isLoading && <Spinner iconSize="1em" />}
      {children || title}
    </button>
  ) : (
    link && (
      <a
        href={link}
        className={mainStyle}
        target={openInTab && '_blank'}
        rel={openInTab && 'noopener noreferrer'}
        id={id}
      >
        {buttonStyle === 'viewLink' && <IconPlayTvguide />}
        {children || title || 'Weiter'}
      </a>
    )
  );
};
