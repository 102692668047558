import { isValid, parse } from 'date-fns';
import { format } from 'date-fns-tz';
import { de } from 'date-fns/locale/de';

export const createDate = (dateString?: string) => {
  try {
    return new Date(dateString.replace(/-/g, '/'));
  } catch (err) {
    return new Date();
  }
};

export const formatDate = (dateString: string, formatOptions?: Intl.DateTimeFormatOptions, locale = 'de-AT') => {
  return new Intl.DateTimeFormat(locale, formatOptions ? formatOptions : {}).format(createDate(dateString));
};

export function getValidatedUrlDate(dateString) {
  if (!isValidDateString(dateString, 'yyyyMMdd')) return new Date();

  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);

  return new Date(`${year}-${month}-${day}`);
}

export function formatSlugDate(inputDate) {
  const date = inputDate.getDate().toString().padStart(2, '0');
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  const year = inputDate.getFullYear();
  return `${year}${month}${date}`;
}

export const calculateAge = (birthday: string, deathday?: string) => {
  const birthdayDate = createDate(birthday);
  const deathdayDate = deathday ? createDate(deathday) : null;
  const now = Date.now();
  const differenceInMs = deathdayDate ? birthdayDate.getTime() - deathdayDate.getTime() : birthdayDate.getTime() - now;
  const date = new Date(differenceInMs);
  return Math.abs(date.getUTCFullYear() + 1 - 1970).toString();
};

export const composeDateString = (dateEl) => {
  if (!isValid(dateEl)) return false;

  return format(dateEl, 'yyyy-MM-dd HH:mm:ss', { timeZone: 'Europe/Vienna' });
};

export const isCurrent = (startTime, stopTime, currentTime) => {
  const start = startTime instanceof Date ? startTime.getTime() : createDate(startTime).getTime();
  const stop = stopTime instanceof Date ? stopTime.getTime() : createDate(stopTime).getTime();
  const currentTimeInMs = new Date(currentTime).getTime();

  return currentTimeInMs > start && currentTimeInMs < stop;
};

export const isCurrentOrPrevious = (startTime, currentTime) => {
  const start = startTime instanceof Date ? startTime.getTime() : createDate(startTime).getTime();

  return currentTime > start;
};

export const isValidDateString = (dateString: string, format = 'yyyy-MM-dd HH:mm:ss') => {
  if (!dateString || typeof dateString !== 'string') return false;
  const parsed = parse(dateString, format, new Date(), { locale: de });
  return isValid(parsed);
};
