import React, { FC } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';

export const IconArrow: FC<PropsWithClassProps> = ({ classProps }) => {
  return (
    <svg className={classProps?.root} width="20" height="15" viewBox="0 0 20 15" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M-0.000338875 8.4512L15.7409 8.4512L10.0287 13.5885L11.5547 14.9609L19.8721 7.48061L11.5547 0.000290217L10.0287 1.3727L15.7409 6.51003L-0.000338687 6.51003L-0.000338875 8.4512Z"
      />
    </svg>
  );
};
