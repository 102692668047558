import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Ressort } from '@lib/graphql/generated';
import { Link } from '../Link/Link';

export interface ArticleCategoryProps extends PropsWithClassProps<'inner'> {
  active?: boolean;
  category?: Ressort;
  isSwitcher?: boolean;
  noLink?: boolean;
}

// eslint-disable-next-line react/display-name
export const ArticleCategory = React.forwardRef<HTMLDivElement, ArticleCategoryProps>(
  ({ active, category, isSwitcher, noLink, classProps }, ref) => {
    const styleClasses = active ? 'border-primary text-primary' : 'border-gray-650/25 text-gray-500';
    if (!category?.title) return;

    const Content = () => (
      <span
        className={twMerge(
          'inline-block cursor-pointer whitespace-nowrap rounded-lg border-0.5 bg-white/80 px-3 py-1.5 text-xs font-bold uppercase leading-body tracking-2px backdrop-blur',
          styleClasses,
          !isSwitcher && '',
        )}
      >
        {category && category.title}
      </span>
    );

    return (
      <div className={classProps?.root} ref={ref}>
        {category ? (
          <>
            <Link href={'/' + encodeURIComponent(category.slug)} noLink={noLink}>
              <Content />
            </Link>
          </>
        ) : (
          <Content />
        )}
      </div>
    );
  },
);
