import React from 'react';
import { Image } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Link } from '../Link/Link';

export interface LogoProps {
  responsive?: boolean;
  width?: number;
  height?: number;
  smallWidth?: number;
  smallHeight?: number;
  link?: boolean;
}

export const Logo = ({
  responsive = false,
  width = 124,
  height = 30,
  smallWidth = 36,
  smallHeight = 39,
  link,
}: LogoProps) => {
  return (
    <Link noLink={!link} href="/">
      {responsive && (
        <div className={twMerge('pointer-events-auto mr-auto items-center md:hidden', link && 'cursor-pointer')}>
          <Image
            src="/assets/logos/tvm/logo-mobile.png"
            width={smallWidth}
            height={smallHeight}
            copyright=""
            draggable="false"
            placeholder={'empty'}
            alt="Logo"
            fetchPriority="high"
          />
        </div>
      )}

      <div
        className={twMerge(
          'pointer-events-auto md:mr-auto',
          link && 'cursor-pointer',
          responsive && 'hidden md:inline-block',
        )}
      >
        <Image
          src="/assets/logos/tvm/logo.png"
          width={width}
          height={height}
          draggable="false"
          copyright=""
          alt="Logo"
          fetchPriority="high"
        />
      </div>
    </Link>
  );
};
