import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export const IconPlayTvguide = ({ classProps }: PropsWithClassProps) => {
  const svgStyle = twMerge('w-4 h-4', classProps?.root);
  return (
    <svg className={svgStyle} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 5.42889C5 5.00345 5 4.79072 5.09984 4.67346C5.18682 4.5713 5.31976 4.50809 5.46367 4.50045C5.62886 4.49169 5.82807 4.60969 6.22648 4.84569L11.4112 7.91679C11.7404 8.11179 11.905 8.20929 11.9624 8.33218C12.0125 8.43963 12.0125 8.56037 11.9624 8.66782C11.905 8.79071 11.7404 8.88821 11.4112 9.08321L6.22648 12.1543C5.82807 12.3903 5.62886 12.5083 5.46367 12.4995C5.31976 12.4919 5.18682 12.4287 5.09984 12.3265C5 12.2093 5 11.9966 5 11.5711V5.42889Z"
        fill="currentColor"
      />
      <rect x="0.5" y="1" width="15" height="15" rx="7.5" stroke="currentColor" />
    </svg>
  );
};
