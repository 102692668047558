import React from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';

interface IconSpinnerProps extends PropsWithClassProps {
  iconSize?: string;
}

export const IconSpinner = ({ iconSize, classProps }: IconSpinnerProps) => {
  return (
    <svg
      className={classProps?.root}
      width={iconSize || '24px'}
      height={iconSize || '24px'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M23 12C23 13.9561 22.4784 15.8768 21.4889 17.5642C20.4995 19.2516 19.078 20.6446 17.3709 21.5997C15.6638 22.5547 13.733 23.0374 11.7773 22.9977C9.82158 22.9581 7.91183 22.3978 6.24483 21.3743C4.57783 20.3509 3.21389 18.9015 2.29356 17.1754C1.37324 15.4494 0.929823 13.5091 1.00902 11.5546C1.08822 9.60015 1.68716 7.70214 2.74413 6.0562C3.80109 4.41026 5.27784 3.07594 7.02218 2.19076"
          stroke="currentColor"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};
