import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { Image, ImageProps, Link, PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { AuthorLine, TextCard } from '@vgn-medien-holding/vgn-react-components';
import { format } from 'date-fns';
import { de } from 'date-fns/locale/de';
import { twMerge } from 'tailwind-merge';
import { ArticleCategory } from '@components/atoms/ArticleCategory/ArticleCategory';
import { FallbackCard } from '@components/atoms/FallbackCard/FallbackCard';
import { createDate } from '@utils/dateHelpers';
import { Article, User } from '@lib/graphql/generated';
import { IconArrow } from '@assets/icon-arrow';

export interface ArticleTeaserCardProps extends PropsWithClassProps<'Image' | 'ImageContainer'> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  article: any;
  hideImage?: boolean;
  hideCategory?: boolean;
  showInitially?: boolean;
  layout?: 'default' | 'compact';
  imagePriority?: ImageProps['priority'];
}

export const ArticleTeaserCard = React.forwardRef<HTMLInputElement | HTMLDivElement, ArticleTeaserCardProps>(
  ({ article, hideImage, hideCategory, showInitially, layout = 'default', imagePriority, classProps }, ref) => {
    const { ref: inViewRef, inView } = useInView({
      triggerOnce: true,
    });

    const authors = [];
    article?.authors?.forEach((a) => {
      authors.push({
        name: a.name,
      });
    });

    const itemVisible = useMemo(() => inView || showInitially, [inView, showInitially]);

    return (
      <Link
        href={'/' + encodeURIComponent(article?.ressort?.slug) + '/' + encodeURIComponent(article.slug)}
        classProps={{ root: twMerge('relative block h-full', classProps?.root) }}
        ref={inViewRef}
      >
        {itemVisible &&
          (layout === 'compact' ? (
            <CompactArticleTeaser classProps={classProps} article={article} authors={authors} ref={ref} />
          ) : (
            <DefaultArticleTeaser
              classProps={classProps}
              article={article}
              hideCategory={hideCategory}
              hideImage={hideImage}
              authors={authors}
              ref={ref}
              imagePriority={imagePriority}
            />
          ))}
      </Link>
    );
  },
);

interface CompactArticleTeaserProps extends PropsWithClassProps<'Image' | 'ImageContainer'> {
  article: {
    authors?: Partial<User>[];
  } & Omit<Partial<Article>, 'authors'>;
  authors: Partial<User>[];
}

const CompactArticleTeaser = React.forwardRef<HTMLInputElement | HTMLDivElement, CompactArticleTeaserProps>(
  ({ classProps, article, authors }, ref) => {
    return (
      <div
        className={twMerge(
          'grid h-full min-h-[160px] w-80 divide-y divide-gray-650/25 overflow-hidden rounded-2xl rounded-t-xl border-0.5 border-gray-400/25 bg-white p-4 text-gray-500 shadow-card-sm dark:border-gray-650/40 dark:bg-gray-650/25',
          classProps?.root,
        )}
        ref={ref}
      >
        <div className="mb-4 grid grid-cols-[1fr,auto] gap-4">
          <p className="text-xl font-bold leading-header text-black dark:text-white">{article.headline}</p>
          <div className="grid h-6 items-center">
            <IconArrow
              classProps={{
                root: 'w-5 h-3.5 dark:text-white text-black',
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-[1fr,auto] gap-2 self-end pt-3 text-xs">
          <div>
            {!article?.hide_authors && (
              <AuthorLine authors={authors as []} classProps={{ names: 'text-white pl-px' }} />
            )}
          </div>
          <div>
            {format(createDate(article?.updated_at || article?.published_at || article?.created_at), 'dd.MM.yyyy', {
              locale: de,
            })}
          </div>
        </div>
      </div>
    );
  },
);

interface DefaultArticleTeaserProps extends PropsWithClassProps<'Image' | 'ImageContainer'> {
  article: {
    authors?: Partial<User>[];
  } & Omit<Partial<Article>, 'authors'>;
  authors: Partial<User>[];
  hideImage?: boolean;
  hideCategory?: boolean;
  imagePriority?: ImageProps['priority'];
}

const DefaultArticleTeaser = React.forwardRef<HTMLInputElement | HTMLDivElement, DefaultArticleTeaserProps>(
  ({ classProps, article, authors, hideImage, hideCategory, imagePriority }, ref) => {
    return (
      <div
        ref={ref}
        className={twMerge(
          'grid h-full overflow-hidden rounded-2xl rounded-t-xl border-0.5 border-t-0 border-gray-400/25 bg-white text-gray-500 shadow-card-sm dark:border-gray-800 dark:bg-gray-650/25',
          !hideImage && 'grid-rows-[auto,1fr]',
        )}
      >
        {!hideImage && (
          <div className={twMerge('relative w-full', classProps?.ImageContainer)}>
            {article?.cover_image?.path ? (
              <div className={twMerge('relative h-full w-full', classProps?.Image)}>
                <Image
                  src={article?.cover_image?.path}
                  alt={article?.headline}
                  fill
                  copyright={article?.cover_image?.copyright}
                  classProps={{ root: 'object-cover' }}
                  sizes="(min-width: 768px) 580px, 100vw"
                  priority={imagePriority}
                  decoding={imagePriority ? 'sync' : 'async'}
                />
              </div>
            ) : (
              <FallbackCard classProps={{ root: 'w-full h-full aspect-video sm:aspect-movie' }} />
            )}
            {!hideCategory && (
              <ArticleCategory
                category={article.ressort}
                active
                noLink={true}
                classProps={{
                  root: 'absolute -bottom-4 ml-6',
                }}
              />
            )}
          </div>
        )}

        <div className="h-full">
          <TextCard
            text={article.headline}
            authors={article?.hide_authors ? [] : (authors as [])}
            date={createDate(article?.updated_at || article?.published_at || article?.created_at)}
            classProps={{
              root: 'grid grid-rows-[1fr,auto] px-6 pt-4 pb-6 h-full gap-4',
              text: 'self-start line-clamp-3 text-xl font-bold text-black dark:text-white pt-2 h-full overflow-hidden',
              authorLine: 'text-gray-400 border-t-0.5 border-gray-500/65 pt-2',
              names: 'text-gray-500',
              date: 'text-gray-500',
            }}
          >
            <div className={twMerge('absolute bottom-10 right-6 h-6 w-6', article?.hide_authors && 'bottom-6')}>
              <IconArrow
                classProps={{
                  root: 'w-5 h-3.5 dark:text-white text-black',
                }}
              />
            </div>
          </TextCard>
        </div>
      </div>
    );
  },
);

ArticleTeaserCard.displayName = 'ArticleTeaserCard';
CompactArticleTeaser.displayName = 'CompactArticleTeaser';
DefaultArticleTeaser.displayName = 'DefaultArticleTeaser';
